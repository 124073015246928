import React from "react"
import {
  Container,
  Main,
  Cutin,
  Content,
  Contact,
  Iframely,
  Seo,
  FeatureImage,
} from "../components"
import { FixedPageH1, FixedPageH2 } from "../elements"
import { graphql } from "gatsby"

const othersPage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="伝統工芸"
        pagedesc="伝統工芸に関する悩みをプロが解決するコンテンツです。"
        pagepath={location.pathname}
        pageimg={data.goldroom.childImageSharp.original.src}
        pageimgw={data.goldroom.childImageSharp.original.width}
        pageimgh={data.goldroom.childImageSharp.original.height}
      />
      <Iframely />
      <FeatureImage fixed={data.goldroom.childImageSharp.fixed} />
      <Cutin>
        <Main>
          <FixedPageH1><h1>伝統工芸</h1></FixedPageH1>
          <Content>
            <FixedPageH2>Topics</FixedPageH2>
            <p style={{ textAlign: "center" }}>ごめんなさい！準備中</p>
          </Content>
          <Content>
            <FixedPageH2>Contact Us</FixedPageH2>
            <Contact />
            <div className="iframely-embed">
              <div
                className="iframely-responsive"
                style={{ height: 140, paddingBottom: 0 }}
              >
                <a
                  href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                  data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                  aria-label="GoogleMaps"
                >
                  {null}
                </a>
              </div>
            </div>
          </Content>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    goldroom: file(relativePath: { eq: "goldroom.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    goldroom: file(relativePath: { eq: "goldroom.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default othersPage
